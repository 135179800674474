<template>
  <div class="carousel slide" id="mySlide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="0"
        class="carousel-indicator-item active"
        aria-current="true"
        aria-label="ilk slayt"
      ></button>

      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="1"
        class="carousel-indicator-item "
        aria-label="ikinci slayt"
      ></button>
      <!-- <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="2"
        class="carousel-indicator-item "
        aria-label="üçüncü slayt"
      ></button> -->
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="2"
        class="carousel-indicator-item "
        aria-current="true"
        aria-label="dördüncü slayt"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="banner banner-1">
       <!-- <img src="images/intek-teknoloji-banner/1.png" class="d-block w-100" alt="slide-1"/> -->
      </div>
        <div class="carousel-caption d-none d-md-block">
          <h2>HER ADIMDA GÜVEN <br> HER ÇÖZÜMDE BAŞARI</h2>
        </div>
    
      </div>
      <div class="carousel-item">
        <div class="banner banner-2"></div>
        <div class="carousel-caption d-none d-md-block">
          <h2>GELECEĞİ ŞEKİLLENDİREN <br> GÜÇLÜ ÇÖZÜMLER</h2>
          
          <p></p> 
        </div>
      </div>
      <!-- <div class="carousel-item">
        <div class="banner banner-3"></div>
        <div class="carousel-caption d-none d-md-block">
         
        </div>
      </div> -->
      <div class="carousel-item">
        <div class="banner banner-3"></div>
        <div class="carousel-caption d-none d-md-block">
          <h2>OTOMASYONDA DOĞRU KONTROL <br> DOĞRU SONUÇ</h2>
        <p></p> 
        </div>
      </div>
    </div>

    <button
      type="button"
      class="carousel-control-prev"
      data-bs-target="#mySlide"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Önceki</span>
    </button>
    <button
      type="button"
      class="carousel-control-next"
      data-bs-target="#mySlide"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Sonraki</span>
    </button>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>

.banner {
  width: 100%;
  height: 700px; 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; /* Yazıyı konumlandırmak için */
  transition: filter 0.3s ease; /* Geçiş efekti */
 
}
.banner-1 {
  background-image: url('@/assets/intek/slider1.jpeg');
}

/* İkinci slayt için arka plan */
.banner-2 {
  background-image: url('@/assets/intek/7.jpeg');
}

/* Üçüncü slayt için arka plan */
/* .banner-3 {
  background-image: url('@/assets/intek/2.jpeg');
} */

/* Dördüncü slayt için arka plan */
.banner-3 {
  background-image: url('@/assets/intek/slider3.jpeg');
}

@media (min-width: 1920px) {
  .banner img {
    width: 100%;
    height: 600px;
    height: auto;
    object-fit: cover;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .banner {
    height: 600px; /* Mobilde daha küçük boyutlar */
  }
}
@media (max-width: 767px) {
  .banner {
    height: 600px; /* Mobilde daha küçük boyutlar */
  }
}
@media (max-width: 540px) {
  .banner {
    height: 400px; /* Mobilde daha küçük boyutlar */
  }
}

@media (max-width: 400px) {
  .banner {
    height: 200px; /* Mobilde daha küçük boyutlar */
  }
}


</style>
  
  