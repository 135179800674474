<template>
  <div  class="carousel-container">
    <Carousel
      v-bind="settings"
      :breakpoints="breakpoints"
      :autoplay="2000"
      :wrap-around="true"
      pause-autoplay-on-hover
    >
      <Slide v-for="(slide, index) in references" :key="index">
        <div class="carousel__item">
          <img
            :src="slide.logo"
            class="ref-img"
          />
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>
  
  <script>
import { ref, onMounted } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { firestore } from "@/firebase/config";
import { query, collection, getDocs, where } from "firebase/firestore";

import "vue3-carousel/dist/carousel.css";

export default {
  name: "Breakpoints",
  components: {
    Carousel,
    Slide,
    Navigation,
  },

  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: "start",
      },
    },
  }),
  setup() {
    const references = ref([]);

    onMounted(async () => {
      let q = query(
        collection(firestore, "referanslar"),
        where("durum", "==", true)
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      references.value = veri;
    });

    return {
      references,
    };
  },
};
</script>
  <style scoped>

</style>