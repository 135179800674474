<template>
  <section class="banner-down">
    <div class="banner-text" :class="{'visible': isVisible}">
      <h3>
        İntek Teknoloji olarak <br /><b>Araştırıyoruz, Geliştiriyoruz, Üretiyoruz</b>
      </h3>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'ScrollAnimation',

  setup() {
    const isVisible = ref(false); // Görünürlük durumu için reaktif değişken

    // Sayfa yüklendikten sonra scroll olayını dinlemeye başla
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    // Scroll işlemi başladığında yazının görünür olup olmadığını kontrol et
    const handleScroll = () => {
      const textElement = document.querySelector('.banner-text');
      const rect = textElement.getBoundingClientRect();

      if (rect.top < window.innerHeight) {
        // Eğer text ekranın görünür alanına girdiyse, yazıyı görünür yap
        isVisible.value = true;
      }
    };

    return { isVisible };
  },
};
</script>

<style scoped>
@keyframes slideIn {
  0% {
    opacity: 0; /* Şeffaflık başlasın */
    transform: translateY(-50px); /* Yukarıdan gelsin */
  }
  100% {
    opacity: 1; /* Görünsün */
    transform: translateY(0); /* Yerine otursun */
  }
}
.banner-down {
  position: relative;
  background-image: url('@/assets/world-gray.png');
  background-size: cover;
  height: 390px;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; 
  display: flex; /* Flexbox kullanımı */
  align-items: center; /* Dikeyde ortala */
}

.banner-text {
  position: relative;
  font-size: 32px;
  color: #145da0;
  font-family: "Montserrat", sans-serif;
  margin-left: 10%;
  opacity: 0; /* Başlangıçta görünmez */
  transform: translateY(50px); /* Başlangıçta aşağıda */
  transition: all 0.7s ease-in-out; /* Geçiş animasyonu */
}
.banner-text.visible {
  opacity: 1; /* Görünür hale gelir */
  transform: translateY(0); /* Orijinal konumuna döner */
}

.banner-text h3 {
  font-size: 32px;
  line-height: 1.5em;
  font-weight: 500;
  color: #145da0;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
}
@media (max-width: 768px) {
  .banner-down {
    height: 250px;
  }
  .banner-text {
    margin-left: 5%; /* Küçük ekranlar için daha az boşluk */
  }
  .banner-text h3 {
    font-size: 25px;
    line-height: 1.2em;
  }
}

@media (max-width: 400px) {
  .banner-text h3 {
    font-size: 22px;
    line-height: 1.2em;
  }
}
</style>